.title-s1{
  margin-bottom: 2px;
  color: #000000 !important;
  font-size: 22px;
  font-weight: 700 !important;
}

.title-s2{
  margin-bottom: 2px;
  color: #000000 !important;
  font-size: 26px;
  font-weight: 700 !important;
}
