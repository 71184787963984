#footer{
  position: relative;

  .newsletter-section {
		position: relative;
		padding: 17px 15px 0 15px;
		width: 100%;
		background-color: #000000;

		h2{
			color: #ffffff;
			font-size: 1.9rem;
			text-align: center;
		}

		form{
			margin-top: 18px;
			text-align: center;
			@extend .flex-center-xy;
			flex-wrap: wrap;

			.form-group{
				width: 600px;
				margin-bottom: 0;
				@include placeholder(#757474 !important, 400, normal, 1.1rem);

				>div{
					width: 100%;
				}

				.form-control{
					height: 46px;
          color: #373737;
					text-align: center;
					font-size: 1.1rem;
					border-radius: 4px;
					border-color: #ffffff;
					background-color: #ffffff;
          text-align: left;
				}
			}

			.btn-send{
				position: relative;
				padding-bottom: 8px;
				margin-left: -15px;
				width: 155px;
				height: 46px;
				color: #ffffff;
				font-size: 1.05rem;
				font-weight: 700;
				text-align: center;
				background-color: $cprimary;
				border-color: $cprimary;
				border-radius: 4px;

				&:hover{
					border-color: $cprimary2;
					background-color: $cprimary2;
				}
			}
		}

		@media screen and (max-width: 991px){
			form{
				.form-group{
					width: calc(90vw - 155px);
				}
			}
		}
		@media screen and (max-width: 575px){
			h2{
				font-size: 1.5rem;
				line-height: 1.1;
			}

			form{
				.form-group{
					width: calc(100% - 127px);
					@include placeholder(#919293, 400, normal, 1.0rem);

					.form-control{
						height: 38px;
						font-size: 1.0;
					}
				}

				.btn-send{
					width: 127px;
					height: 38px;
					font-size: 1.0rem;
				}
			}
		}
	}

  .footer-section{
    position: relative;
    padding: 20px 0 0 0;
    color: #ffffff;
    background-color: #000000;

    a{
      display: inline-block;
      color: #ffffff;
    }

    .col-footer{
      padding-top: 14px;
      padding-bottom: 14px;

      h5{
        margin-bottom: 10px;
        color: #9c9c9c;
        font-size: 1.05rem;
        font-weight: 500;
      }

      .link-footer{
        font-size: 0.95rem;
        font-weight: 400;
      }

      .copy-text{
        font-weight: 500;
      }
    }

    .col-copyright{
      text-align: right;

      .p-sm{
        font-size: 0.86rem;
        line-height: 1.35;
      }

      .btn-network{
        position: relative;
        display: inline-block;
        margin: 0 2px;

        span{
          @include flex-center-xy();
          width: 38px;
          flex: 0 0 38px;
          max-width: 38px;
          height: 38px;
          color: #000000;
          font-size: 1.2rem;
          border-radius: 50%;
          background: #ffffff;
        }

        &:hover{
          opacity: 0.8;
        }
      }
    }

    .box-apps {
      position: relative;
      padding: 25px 0;
      text-align: center;
      border-top: 1px solid #282828;
      background: #0b0a0a;

      .title {
        display: inline-block;
        padding-right: 8px;
        margin-bottom: 15px;
        color: #ffffff;
        font-size: 1.10rem;
        font-weight: 500;
      }

      .btn-app {
        display: inline-block;
        margin: 0 10px;

        img {
          height: 50px;
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-footer{
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: center;

        h5{
          margin-bottom: 2px;
          font-size: 1.05rem;
        }
      }

      .box-apps {
        margin-top: 8px;
      }
    }
    @media screen and (max-width: 575px){
      .col-footer{
        font-size: 1.0rem;
      }

      .box-apps {
        padding: 18px 0;
        z-index: 99;

        .title {
          display: block;
        }

        .btn-app {
          img {
            height: 45px;
          }
        }
      }
    }
  }
}
