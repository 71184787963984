#text-page{
  padding-top: 15px;
  padding-bottom: 30px;
  color: #403f3f;
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: justify;
  min-height: 67vh;

  .page-title{
    color: $csecondary;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
  }

  ul,ol{
    padding-left: 16px;

    li{}
  }

  strong {
    font-weight: 600 !important;
  }

  ul, ol, li, span, p, {
    color: #403f3f !important;
  }

  h6, h5, h4,h3, h2, h1 {
    color: #171717 !important;

    strong, span {
      color: #171717 !important;
    }

    strong {
      font-weight: 700 !important;
    }
  }
  h5{
    font-size: 1.1rem;
    font-weight: 600;
  }
  h6{
    font-size: 0.95rem;
    font-weight: 600;
  }

  .ql-align-center {
    text-align: center !important;
  }
}
