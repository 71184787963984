.btn-s1{
  width: 100%;
  color: #ffffff !important;

  &.b-pink{
    border-color: $csecondary;
    background-color: $csecondary;

    &:hover, &:focus, &:active{
      border-color: $csecondary2 !important;
      background-color: $csecondary2 !important;
    }
  }
}
