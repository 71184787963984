#home-page{

  // == Banners ==
	.banner-section{
		padding: 0;

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 991px){
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile	{
				display: block;
			}
		}
	}
	// == ==

	// == Categories v2 ==
	.categories-v2-section{
		position: relative;
		padding-top: 23px;
		padding-bottom: 20px;
		background-color: rgba(69, 159, 28, 0.15);

		.container{
			max-width: 100% !important;

			.col-title{
				text-align: center;
				margin-bottom: 0;
			}

			.swiper{
				.swiper-slide{
					padding-bottom: 38px;
				}

				.swiper-pagination{
					bottom: 0;

					.swiper-pagination-bullet{
						width: 10px;
						height: 10px;
					}

					.swiper-pagination-bullet-active{
						background-color: $cprimary !important;
					}
				}
			}

			.col-categories{
				.content{
					padding: 10px 16px;
					border-radius: 3px;
					background-color: #ffffff;

					.category-name{
						margin-bottom: 1px;
						text-align: center;
					}

					.box-subcategories{
						display: flex;
						flex-wrap: wrap;
						margin-left: -8px;
						margin-right: -8px;

						.col-subcategory{
							padding: 8px;

							.box-subcat{
								display: block;

								.image{
									display: block;
									margin-bottom: 7px;
									box-shadow: 0 0 2px rgba(0,0,0,0.4);
									background-color: $soft-gray;

									img{
										width: 100%;
									}

									&.xl{
										@media screen and (min-width: 1200px){
											height: 32.5vw;
										}
									}
								}

								h6{
									height: 47px;
									max-height: 47px;
									color: #3e3e3e;
									font-size: 0.90rem;
									font-weight: 500;
									line-height: 1.05;
									text-align: center;
								}
							}

							a.box-subcat{
								text-decoration: none !important;

								&:hover{
									opacity: 0.9;
								}
							}
						}

						.col-more{
							padding-bottom: 2px;
							text-align: center;

							a{
								color: #6a6d71;
								font-weight: 600;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px){
			.container{
				.main-row{
					margin-left: -10px;
					margin-right: -10px;
				}
				.col-title, .col-categories{
					padding: 10px;
				}
			}
		}
		@media screen and (max-width: 991px){
			.container{
				.col-categories{
					margin-bottom: 10px;
				}
			}
		}
		@media screen and (max-width: 575px){
			padding-top: 12px;
			padding-bottom: 12px;

			.container{
				.col-title{
					margin-bottom: 6px;
				}

				.swiper{
					.swiper-pagination{
						bottom: 5px;
					}
				}
			}
		}
	}
	// == ==

	// == Extra info ==
	.extra-info-section{
		margin-top: 30px;
		margin-bottom: 5px;

		>.row{
			margin-left: -10px;
			margin-right: -10px;
		}

		.col-box{
			padding: 10px;
			@media screen and (min-width: 1200px){
				flex: 0 0 20%;
				max-width: 20%;
			}

			.inside{
				display: inline-block;
				padding: 13px;
				// color: #ffffff;
				color: #5d6064;
				width: 100%;
				height: 100%;
				// border: 2px solid $purple;
				// border-radius: 6px;
				// background-color: $purple;
				// background: linear-gradient(0deg, #1e3661 0%, #144e9b 100%);
				text-decoration: none !important;
				@include transition(250ms);

				&:hover{
					opacity: 0.90;
				}

				.box-image{
					display: inline-block;
					width: 100%;
					height: 155px;
					margin-bottom: 10px;
					text-align: center;

					img{
						max-width: 90%;
						max-height: 153px;
					}
				}

				.box-text{
					width: 100%;
					text-align: center;
					@include flex-center-xy();
					text-transform: uppercase;
					flex: 0 0 100%;
					max-width: 100%;
					// height: 100%;

					div{
						display: inline-block;
						width: 100%;

						h6{
							font-size: 1.1rem;
							color: #8c8287;
							line-height: 1.2;
							font-weight: 900;
						}
					}
				}
			}
		}

		@media screen and (max-width: 575px){
			.col-box{
				.inside{
					padding: 0;

					.box-image{
						height: 82px;

						img{
							max-height: 80px;
						}
					}

					.box-text{
						div{
							h6{
								font-size: 0.85rem;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
	// == ==

  // == Categories ==
  .categories-section{
    padding-top: 15px;
    padding-bottom: 15px;

    .swiper{
      .swiper-slide{
        .box-category{
          position: relative;
					display: block;
					text-decoration: none !important;
          background-color: $soft-gray;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					&:before{
						content: " ";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0,0,0,0.45);
						z-index: 1;
					}

          &:hover{
            opacity: 0.85;
          }

					.box{
						position: absolute;
						@include flex-center-xy();
						top: 0;
						left: 0;
	          width: 100%;
	          max-width: 100%;
						height: 100%;

						.inside{
							position: relative;
	            display: inline-block;
	            padding: 10px 16px;
	            width: 100%;
	            text-align: center;
							z-index: 4;

	            h5{
	              color: #ffffff;
	              font-size: 1.55rem;
	              font-weight: 400;
	            }
	          }
					}

					.empty{
						width: 100%;
					}
				}
      }
    }
  }
  // == ==

  // == Register s1 ==
  .register-section-s1{
    margin-bottom: 20px;

    .btn-register{
      display: block;
      padding: 16px 25px;
      color: #ffffff;
      font-size: 1.7rem;
      font-weight: 600;
      text-align: center;
      text-decoration: none !important;

      &.btn-green{
        background-color: $cprimary;

        &:hover{
          background-color: $cprimary2;
        }
      }

      &.btn-pink{
        background-color: $csecondary;

        &:hover{
          background-color: $csecondary2;
        }
      }
    }

    @media screen and (max-width: 991px){
      .btn-register{
        padding: 12px 25px;
        font-size: 1.5rem;
      }
    }
    @media screen and (max-width: 414px){
      .btn-register{
        padding: 11px 25px;
        font-size: 1.2rem;
        line-height: 1.25;
      }
    }
  }
  // ==

  // == Products ==
	.products-section{
    .col-title{
      .title-s1{
        padding-left: 4px;
        text-align: center;
      }
    }

		.col-products{
			.carousel-t1{
				.swiper-button-prev, .swiper-button-next{
					top: 32%;
				}
			}
		}
	}
	// == ==

	// == Mid banners ==
	.mid-banners-section{
		.col-banner{
			a{
				display: block;

				img{
					width: 100%;
				}
			}
		}

		@media screen and (max-width: 991px){
			.col-banner{
				&:first-child{
					margin-bottom: 6px;
				}
			}
		}
	}
	// == ==

	// == Blog ==
	.blog-section{
		position: relative;
		padding: 26px 0 32px 0;
		background-color: #f7f7f7;

		.col-articles{
			.swiper{
				.swiper-button-prev, .swiper-button-next{
					color: #ffffff;

					&:after{
						font-size: 2.2rem;
					}
				}
			}
		}
	}
	// == ==

	// == Brands ==
	.brands-section{
		.col-brands{
			margin-bottom: 15px;

			.swiper{
				.swiper-slide{
					.box-brand{
						@extend .flex-center-xy;
						flex-wrap: wrap;
						flex: 0 0 100%;
						max-width: 100%;
						height: 165px;

						.inside{
							display: inline-block;
							width: 100%;
							text-align: center;

							img{
								max-width: 90%;
								max-height: 150px;
							}
						}

						@media screen and (min-width: 992px) and (max-width: 1199px){
							height: 135px;

							.inside{
								img{
									max-width: 100%;
									max-height: 135px;
								}
							}
						}
						@media screen and (max-width: 767px){
							.inside{
								img{
									max-width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
	// == ==

}
