.article-sample-1{
  position: relative;

  .article-link{
    position: relative;
    display: inline-block;
    width: 100%;
    // height: 275px;
    color: #ffffff;
    background-color: $soft-gray;

    .empty{
      width: 100%;
    }

    .inside{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .show{
        position: absolute;
        padding: 5px 14px;
        top: 11px;
        right: 0;
        color: #ffffff;
        font-size: 0.90rem;
        font-weight: 600;
        background-color: $cprimary;
      }

      .shadow{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 54%;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        z-index: 1;
      }

      .box-txt{
        position: absolute;
        padding: 22px;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 4;

        h5{
          margin-bottom: 5px;
          font-size: 1.10rem;
          font-weight: 500;
          line-height: 1.0;
        }
        h6{
          margin-bottom: 6px;
          font-size: 0.90rem;
          font-weight: 300;
        }
        .descr{
          position: relative;
          max-height: 39px;
          line-height: 1.2;
          overflow: hidden;
        }
      }
    }

    &:hover{
      opacity: 0.90;

      .inside{
        .show{
          padding: 5px 18px;
        }
      }
    }
  }
}
