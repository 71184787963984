.col-rating-form{
  .box-rating-form{
    position: relative;
    width: 100%;

    h5{
      margin-bottom: 10px;
      font-size: 1.15rem;
      font-weight: 700;
    }

    .box-stars{
      position: relative;
      display: inline-block;
      width: 100%;

      .b-rating{
        height: auto;
        padding: 0;
        // width: 230px;
        border: 0;

        .b-rating-star{
          font-size: 1.45rem;
          padding: 0 0.40em;

          &:first-child{
            padding-left: 0;
          }
        }
      }
    }

    .box-input{
      position: relative;
      display: inline-block;
      margin-top: 5px;
      width: 96%;

      @media screen and (max-width: 767px){
        width: 100%;
      }

      textarea{
        resize: none;
      }

      .btn-co{
        width: 190px;
        font-size: 1.05rem;
        font-weight: 500;
        letter-spacing: 2px;
        border-color: $csecondary;
        background-color: $csecondary;

        &:hover{
          background-color: $csecondary2;
        }
      }
    }
  }
}
