// == Blog index ==
#blog-page{
  .articles-section{
    padding-top: 15px;
    padding-bottom: 12px;

    .col-page-title{
      text-align: center;
    }

    .col-article{
      margin-bottom: 25px;
    }

    @media screen and (max-width: 575px){
      .col-article{
        margin-bottom: 15px;
      }
    }
  }
}
// == ==

// == Article page (blog detail) ==
#article-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .content-section{
    .col-page-title-t2{
      margin-bottom: 12px;
    }

    .col-article{
      .image-s2{
        display: inline-block;
        width: 100%;
      }
      .box-image{
        display: inline-block;
        margin-bottom: 3px;
        width: 100%;

        img{
          width: 100%;
        }
      }

      .title{
        color: $csecondary;
        font-size: 1.5rem;
        font-weight: 500;
      }

      .descr{
        display: inline-block;
        margin-top: 6px;
        width: 100%;
        color: #5d5d5d;
        font-size: 0.95rem;
        line-height: 1.3;
        text-align: justify;

        a{
          color: $csecondary
        }

        ul,ol{
          padding-left: 19px;
        }

        img{
          max-width: 100% !important;
        }
      }
    }

    .col-articles{
      .article-sample-1{
        margin-bottom: 10px;

        .article-link{
          max-height: 260px;
          overflow: hidden;

          .empty{
            max-height: 260px;
          }
        }
      }
    }
  }

}
// == ==
