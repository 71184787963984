#header{
	position: relative;
	width: 100%;
	height: 75px;
	z-index: 99;

	.header-v2 {
		position: fixed;
		padding-top: 12px;
  	padding-bottom: 14px;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 2px 3px rgba(0,0,0,0.1);
		background: #f1f1f1;
		z-index: 99;

		>.container {
			>.row {
				align-items: center;
			}
		}

		.col-logo {
			a {
				img {
					max-width: 100%;
					height: 50px;
				}
			}
		}

		.col-info {
			text-align: right;

			a {
				color: $ctertiary;
				font-size: 1.1rem;
				font-weight: 600;
				letter-spacing: 2px;
			}
		}
	}

	@media screen and (min-width: 992px) {
		.header-v2 {
			.col-logo {
				padding-left: 0;
			}

			.col-info {
				padding-right: 0;
			}
		}
	}
	@media screen and (max-width: 991px) {
		height: 61px;

		.header-v2 {
			.col-logo {
				a {
					img {
						height: 35px;
					}
				}
			}

			.col-info {
				a {
					font-size: 1.0rem;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.header-v2 {
			.col-info {
				a {
					font-size: 0.85rem;
				}
			}
		}
	}

	// @media screen and (max-width: 991px){
	// 	height: 79px;
	// }
	//
	// .header-content{
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// }
	//
	// .header-temp{
	// 	position: relative;
	// 	padding: 5px 15px 9px 15px;
	// 	color: #ffffff;
	// 	text-align: center;
	// 	background-color: $csecondary;
	//
	// 	h5{
	// 		font-weight: 600;
	// 		font-size: 0.90rem;
	// 		text-transform: uppercase;
	// 	}
	//
	// 	h6{
	// 		font-size: 0.85rem;
	// 		line-height: 1.0;
	// 	}
	//
	// 	@media screen and (max-width: 575px){
	// 		h5{
	// 			font-size: 0.85rem;
	// 			font-weight: 700;
	// 		}
	//
	// 		h6{
	// 			font-size: 0.75rem;
	// 		}
	// 	}
	// }
	//
	// .header-top{
	// 	@media screen and (max-width: 991px){
	// 		display: none !important;
	// 	}
	//
	// 	.navbar{
	// 		.navbar-brand{
	// 			img{
	// 				height: 45px;
	// 			}
	// 		}
	//
	// 		#nav-collapse{
	// 			.navbar-nav{
	// 				.nav-item{
	// 					.nav-link{
	// 						@include transition(150ms);
	// 						color: #000000;
	// 						cursor: pointer;
	//
	// 						&:hover{
	// 							opacity: 0.8;
	// 						}
	// 					}
	// 				}
	//
	// 				.special-item{
	// 					.nav-link{
	// 						position: relative;
	// 						top: 4px;
	// 						padding-left: 1.2rem;
	// 						padding-right: 1.2rem;
	// 						color: #ffffff;
	// 						font-size: 0.9rem;
	// 						font-weight: 500;
	// 						border-radius: 5px;
	// 						background-color: #000000;
	// 					}
	//
	// 					&.green-s-item{
	// 						.nav-link{
	// 							background-color: $csecondary;
	//
	// 							&:hover{
	// 								background-color: $csecondary2;
	// 							}
	// 						}
	// 					}
	// 				}
	//
	// 				.nav-icon{
	// 					>.nav-link{
	// 						position: relative;
	// 						font-size: 1.2rem;
	//
	// 						.num {
	// 							position: absolute;
	// 							@include flex-center-xy();
	// 							top: 1px;
	// 							right: -1px;
	// 							color: #ffffff;
	// 							font-size: 0.70rem;
	// 							font-weight: 600;
	// 							width: 20px;
	// 							height: 20px;
	// 							border-radius: 50%;
	// 							background-color: $csecondary;
	// 							z-index: 80;
	// 						}
	// 					}
	// 				}
	//
	// 				.dropdown{
	// 					&.dropdown-lang{
	// 						.nav-link{
	// 							span{
	// 								font-size: 0.8rem;
	// 								font-weight: 500;
	// 							}
	// 						}
	// 					}
	//
	// 					.dropdown-menu{
	// 						padding: 0;
	// 						border-radius: 0;
	// 						// border-color: rgba(255,255,255,0.3);
	//
	// 						li{
	// 							.dropdown-item{
	// 								padding: 0.3rem 1.2rem;
	// 								font-size: 0.9rem;
	// 								border: 1px solid transparent;
	//
	// 								&:hover, &:focus, &:active{
	// 									color: #ffffff !important;
	// 									background-color: #000000 !important;
	// 									border-color: rgba(255,255,255,0.2);
	// 								}
	// 							}
	// 						}
	// 					}
	// 				}
	//
	// 			}
	// 		}
	// 	}
	// }
	//
	// .header-bottom{
	// 	position: relative;
	//
	// 	.navbar{
	// 		&.bg-black{
	// 			background-color: #000000;
	// 		}
	//
	// 		.navbar-brand{
	// 			padding-top: 0.1rem;
	// 			padding-bottom: 0.1rem;
	//
	// 			img{
	// 				height: 27px;
	// 			}
	// 		}
	//
	// 		.navbar-toggler{
	// 			color: #ffffff;
	// 			font-size: 1.6rem;
	// 			border: 0;
	// 		}
	//
	// 		#nav-collapse{
	// 			.navbar-nav{
	// 				.simple-item{
	// 					.nav-link{
	// 						color: #ffffff;
	// 						cursor: pointer;
	// 						font-size: 0.95rem;
	// 						font-weight: 600;
	//
	// 						.icon-sort-down{
	// 							position: relative;
	// 							top: -2px;
	// 							font-size: 90%;
	// 							opacity: 0.75;
	// 						}
	// 					}
	// 				}
	//
	// 				.networks-item{
	// 					.nav-link{
	// 						padding-top: 0;
	// 						padding-bottom: 0;
	//
	// 						.btn-network{
	// 							display: inline-block;
	// 							margin: 0 3px;
	// 							width: 32px;
	// 							height: 32px;
	// 							color: #000000;
	// 							text-align: center;
	// 							background-color: #ffffff;
	// 							border-radius: 50%;
	// 							@include transition(150ms);
	//
	// 							i{
	// 								line-height: 1.6;
	// 								font-size: 1.2rem;
	// 							}
	//
	// 							&:hover{
	// 								opacity: 0.8;
	// 							}
	// 						}
	// 					}
	// 				}
	//
	// 				.cart-item-mb {
	// 					.nav-link {
	// 						.num {
	// 							display: inline-block;
	// 							padding: 3px 3px 2px 2px;
	// 							min-width: 24px;
	// 							font-size: 0.75rem;
	// 							font-weight: 600;
	// 							line-height: 1;
	// 							text-align: center;
	// 							border-radius: 3px;
	// 							background: $csecondary;
	// 						}
	// 					}
	// 				}
	//
	// 				.dropdown{
	// 					.dropdown-menu{
	// 						li{
	// 							.dropdown-item{
	// 								&:hover, &:focus, &:active{
	// 									color: #ffffff !important;
	// 									background-color: #000000 !important;
	// 									border-color: rgba(255,255,255,0.2);
	// 								}
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	//
	// 	@media screen and (min-width: 992px){
	// 		.navbar{
	// 			#nav-collapse{
	// 				.navbar-nav{
	// 					.simple-item{
	// 						margin-left: 17px;
	// 						margin-right: 17px;
	//
	// 						&:first-child{
	// 							margin-left: 0;
	// 						}
	// 						&:last-child{
	// 							margin-right: 0;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// 	@media screen and (max-width: 991px){
	// 		.navbar{
	// 			#nav-collapse{
	// 				padding-top: 6px;
	//
	// 				.navbar-nav{
	// 					.simple-item{
	// 						.nav-link{
	// 							padding-top: 0.20rem;
	// 							padding-bottom: 0.20rem;
	// 							font-weight: 400;
	// 						}
	// 					}
	//
	// 					.networks-item{
	// 						margin-top: 5px;
	// 						margin-bottom: 5px;
	//
	// 						.nav-link{
	// 							text-align: center;
	//
	// 							.btn-network{
	// 								i{
	// 									line-height: 1.7;
	// 								}
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// // == Products menu ==
	.products-menu{
		position: fixed;
		padding-top: 15px;
		padding-bottom: 15px;
		top: 0;
		left: 0;
		width: 335px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
		background: #1b1c1b;
		opacity: 0;
		visibility: hidden;
		z-index: 480;
		@include cp-property(transform, translateX(-380px) !important);

		a{
			color: #fff;
		}

		.menu-bg{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			z-index: 1;
		}

		.tablist{
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			z-index: 2;

			.box-title{
				padding: 8px 0px;
				margin-bottom: 6px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}

				h4{
					font-size: 1.3rem;
				}

				.icon{
					position: absolute;
					top: 13px;
					right: 24px;
					font-size: 1.2rem;
				}
			}
		}

		.card{
			border: 0;
			border-radius: 0;
			background-color: transparent;

			.card-header{
				background-color: transparent;
				border: 0;
				border-radius: 0;
				padding: 0.2rem;

				.btn-toggle{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:after{
						content: " ";
						position: absolute;
						top: 7px;
						right: 18px;
						width: 16px;
						height: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('../images/shared/chevron-down.svg');
						z-index: 1;
					}

					&:hover{
						opacity: 0.8;
					}
				}
				.btn-toggle-fake{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:hover{
						opacity: 0.8;
					}
				}

				.btn-direct-link{
					position: relative;
					right: -43px;
					padding-right: 43px;
				}
			}

			.card-body{
				padding: 0.4rem 0;
				line-height: 1.3;
				background-color: #363736;

				.ul-sub-options{
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li{
						margin-top: 5px;
						margin-bottom: 5px;
						display: inline-block;
						width: 100%;
						font-size: 14px;
						text-align: right;

						a{
							display: block;
							padding: 0px 1.45rem;

							&:hover{
								text-decoration: none;
								opacity: 0.8;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			visibility: visible;
			@include cp-property(transform, translateX(0px) !important);
		}
	}
	// == ==

	// == Search form & autocomplete ==
	.search-form{
		position: relative;
		width: 36.5vw;
		@include placeholder(#979797, 400, italic, 0.875rem);

		@media screen and (max-width: 1199px){
			width: 31vw;
		}
		@media screen and (max-width: 1099px){
			width: 23vw;
		}
		@media screen and (max-width: 991px){
			margin-top: 3px;
			margin-bottom: 7px;
			width: 100%;
		}

		>.form-inline{
			width: 100%;
		}

		.form-control{
			padding: 0.4rem 0.5rem 0.4rem 44px;
			width: 100%;
			height: auto !important;
			color: #7f7f80;
			font-weight: 300;
			border-radius: 30px;
			border-color: $csecondary;
			background-color: #f0f0f0;
			z-index: 50;
		}

		.btn-search{
			position: absolute;
			top: 8px;
			left: 12px;
			width: 26px;
			height: 19px;
			font-size: 0.875rem;
			border: 0 !important;
			background-image: url('../images/shared/search-icon.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-color: transparent;
			z-index: 55;
		}

		.btn-empty{
			position: absolute;
			padding: 0;
			top: 6px;
			right: 8px;
			width: 28px;
			height: 28px;
			font-size: 0.875rem;
			border: 0 !important;
			background-color: transparent !important;
			z-index: 55;

			&:after{
				content: "\f00d";
				font-family: "Font Awesome 5 Pro";
				font-size: 1.1rem;
				font-weight: 300;
				line-height: 1;
				color: #999999;
			}

			&:hover{
				&:after{
					color: #555555;
				}
			}
		}

		.box-autocomplete{
			position: absolute;
			bottom: 19px;
			left: 0;
			width: 100%;
			height: 1px;
			z-index: 49;
			opacity: 0;
			visibility: hidden;
			@include transition(50ms);

			&.active{
				opacity: 1;
				visibility: visible;
			}

			.box{
				position: relative;
				padding-top: 16px;
				width: 100%;
				border: 1px solid $csecondary;
				border-radius: 0 0 0.50rem 0.50rem;
				background-color: #f0f0f0;

				.box-results{
					position: relative;
					display: block;
					padding: 2px 0 0 0;

					.row-product{
						display: flex;
						flex-wrap: wrap;
						flex: 0 0 100%;
						max-width: 100%;
						padding: 1px 12px;
						margin: 4px 0;

						&:hover{
							text-decoration: none !important;
							box-shadow: 0 0 3px rgba(0,0,0,0.3);
						}

						.img{
							padding: 0;
							flex: 0 0 38px;
							height: 30px;
							background-position: center center;
							background-repeat: no-repeat;
							background-size: cover;
							background-color: #ffffff;
						}

						.txt{
							padding-right: 5px;
							white-space: nowrap;
							overflow: hidden !important;
							text-overflow: ellipsis;
							color: #646262;

							.p-name{
								font-size: 0.85rem;
								font-weight: 600;
								line-height: 1.0;
							}

							.cat-subcat{
								font-size: 0.70rem;
								font-weight: 500;
							}
						}

						&.no-shad{
							box-shadow: none !important;
						}

						&.all{
							margin: 3px 0 0 0;
							padding: 5px 12px;
							border-radius: 0 0 6px 6px;
							background: $csecondary;

							.txt{
								color: #ffffff !important;
								font-size: 0.85rem !important;
							}

							&:hover{
								background-color: $csecondary2;
							}
						}
					}
				}
			}
		}
	}
	// == ==

}
