#about-us-page-v2{
  position: relative;

  .img-bg{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 47%;
    height: 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 991px){
      opacity: 0.2;
    }
    @media screen and (max-width: 575px){
      width: 100%;
    }
  }

  .main-section{
    padding-top: 35px;
    padding-bottom: 35px;

    .col-image{
      img{
        width: 100%;
      }
    }

    .col-info{
      color: #575757;
      line-height: 1.35;

      .col-s-title{
        padding-top: 10px;
      }

      h2,h3,h4,h5,h6{
        color: #3e3e3e;
        font-weight: 500;
      }

      ul,ol{
        padding-left: 15px;
        margin-bottom: 0;
      }
      ul{
        list-style-type: circle;
      }

      strong, b{
        font-weight: 600;
      }
    }

    @media screen and (max-width: 991px){
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media screen and (max-width: 575px){
      .col-info{
        font-size: 0.96rem;
        line-height: 1.3;

        .col-s-title{
          padding-bottom: 5px;
        }
      }
    }
  }

}
