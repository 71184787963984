.loader-circular {
  border-top-color: $csecondary !important;

  &:before {
    border-top-color: $ctertiary !important;
  }
  &:after {
    border-top-color: #ffffff !important;
  }
}
