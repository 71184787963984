.sample-products-wrapper{
  margin-left: -10px;
  margin-right: -10px;

  .sample-product{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  &.xxl-4e{
    .sample-product{
      @media screen and (min-width: 1400px){
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  &.xxl-5e{
    .sample-product{
      @media screen and (min-width: 1400px){
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

.sample-product{
  &.shad-prod{
    .outlined-box{
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }

  .outlined-box{
    cursor: pointer;
    @include transition(200ms);

    &:hover{
      text-decoration: none !important;
      opacity: 0.8;
      border-color: #797b7b;
    }
  }

  .outlined-box{
    display: inline-block;
    width: 100%;
    background-color: #ffffff;
    // border: 1px solid #E0E0E0;
    border: 0;
    border-radius: 0;

    .out-of-stock{
      position: absolute;
      padding: 4px 14px 5px 14px;
      bottom: 10px;
      right: 0;
      color: #ffffff;
      font-size: 0.80rem;
      font-weight: 500;
      text-align: center;
      background-color: #f43c3c;
    }

    .box-image{
      position: relative;
      @extend .flex-center-xy;
      height: 100%;
      text-align: center;
      border-bottom: 1px solid rgba(0,0,0,0.2);

      .img{
        display: inline-block;
        max-width: 100%;
        text-align: center;

        img{
          display: inline-block;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .box-shot-descr{
      display: block;
      min-height: 108px;
      // overflow: auto;
      // overflow-x: hidden;
      padding: 10px 18px 5px 18px;
      text-align: center;

      .sku{
        margin-bottom: 4px;
        color: #959595;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.0;
        word-break: break-word;
      }

      .brand{
        margin-bottom: 4px;
        min-height: 30px;
        color: #959595;
        font-size: 0.95rem;
        font-weight: 600;
        line-height: 1.0;
        word-break: break-word;
      }

      .name{
        width: 100%;
        min-height: 72px;
        color: #000000;
        font-size: 0.95rem;
        word-break: break-word;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;

        &.para-5{
          min-height: 91px;
        }
      }

      .price{
        margin-top: 3px;
        margin-bottom: 0;
        min-height: 44px;
        color: $cprimary;
        font-size: 1.1rem;
        line-height: 1.0;

        &.discount{
          span{
            display: block;
          }

          .cross{
            margin-bottom: 0;
            color: #b3b2b2;
            text-decoration: line-through;
            font-size: 92%;
          }
        }
      }

      .descr{
        height: 35px;
        color: #939393;
        font-size: 0.85rem;
        font-weight: 300;
        line-height: 1.3;
        overflow: hidden;
      }
    }

    .p-button{
      margin-bottom: 0;
      text-align: center;

      .btn-more{
        display: inline-block;
        padding: 10px 30px;
        width: 100%;
        color: #ffffff;
        font-size: 1.0rem;
        font-weight: 500;
        text-decoration: none !important;
        border-radius: 1px;
        background-color: $cprimary;
      }
    }
  }
}

.sample-product-s2{
  padding-top: 15px;
  padding-bottom: 15px;

  .box-category{
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: $soft-gray;

    img{
      width: 100%;
    }

    .inside{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;

      .name{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 9px 15px;
        margin-bottom: 30px;
        width: 100%;
        min-height: 57px;
        color: #ffffff;
        background-color: rgba(0,0,0,0.70);

        h6{
          line-height: 1.0;
          font-size: 1.2rem;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}
