// == Modal search (Header) ==
.modal-search-e{
  .modal-content{
    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.85rem 1rem;
        top: 10px;
        right: 16px;
        font-size: 28px;
        font-weight: 600;
      }
    }

    .modal-body{
      padding: 0;

      form{
        position: relative;
        width: 100%;

        .form-group{
          margin: 0 !important;

          .form-control{
            padding-left: 78px;
            padding-right: 38px;
            padding-bottom: 0.6rem;
          }
        }

        .btn-search{
          position: absolute;
          top: 0;
          left: 0;
          width: 56px;
          height: 100%;
          color: #ffffff;
          font-size: 18px;
          border: 0 !important;
          background-color: $csecondary;
          z-index: 10;

          &:hover, &:focus, &:active{
            background-color: $csecondary2 !important;
          }
        }
      }
    }
  }
}
// == ==

// == Modal image ==
.modal-product-gallery{
  .modal-content{
    overflow: hidden;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.85rem 1rem;
        top: 10px;
        right: 16px;
        color: $csecondary;
        font-size: 44px;
        font-weight: 500;
        opacity: 1;

        &:hover{
          opacity: 0.85;
        }
      }
    }

    .modal-body{
      padding: 0;

      img{
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .swiper{
        .swiper-slide{
          img{}
        }

        .swiper-button-prev, .swiper-button-next{
          width: 42px;
          height: 42px;
          color: #ffffff;
          background-color: $csecondary;
          border-radius: 50%;

          &:after{
            font-size: 1.2rem;
            font-weight: 700;
          }
        }

        .swiper-button-prev{
          &:after{
            margin-right: 3px;
          }
        }

        .swiper-button-next{
          &:after{
            margin-left: 1px;
          }
        }
      }

      .video-iframe {
        margin-bottom: -9px;
        width: 100%;
        height: 450px;
        cursor: pointer;
      }
    }
  }
}
// == ==

// == Modal image ==
.modal-info{
  .modal-content{
    overflow: hidden;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.85rem 1rem;
        top: 10px;
        right: 16px;
        color: $csecondary;
        font-size: 44px;
        font-weight: 500;
        opacity: 1;

        &:hover{
          opacity: 0.85;
        }
      }
    }

    .modal-body {
      color: #2d2c2c;
      font-size: 0.93rem;
      line-height: 1.35;

      .tyc-title {
        margin-bottom: 10px;
        font-size: 1.45rem;
        font-weight: 600;
        text-align: center;
      }

      h5 {
        font-size: 1.10rem;
        font-weight: 600;
      }
    }
  }
}
// == ==
