#landing-page {

  .banner-section {
    img {
      width: 100%;
    }
  }

  .about-membership-section {
    position: relative;

    .green-line {
      position: relative;
      margin: 20px 0;
      width: 100%;
      height: 29px;
      background-color: $cprimary;
    }

    .col-steps {
      .title {
        text-transform: uppercase;
        font-weight: 800;
        line-height: 0.8;

        .gray {
          display: inline-block;
          color: #616161;

          &.g2 {
            position: relative;
            @media screen and (min-width: 1200px) {
              bottom: 8px;
            }
          }
        }

        .pink {
          display: inline-block;
          color: $csecondary;
          font-size: 220%;
          font-weight: 900;
        }
      }

      .pink-hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border-top: 10px solid $csecondary;
      }

      .row-step {
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;

        .col-num {
          flex: 0 0 90px;
          max-width: 90px;
          line-height: 1;

          span {
            flex: 0 0 60px;
            max-width: 60px;
            height: 60px;
            color: #ffffff;
            font-size: 2.4rem;
            font-weight: 800;
            border-radius: 50%;

            &.pink {
              background-color: $csecondary;
            }
            &.green {
              background-color: $cprimary;
            }
          }
        }

        .col-text {
          padding-left: 5px;
          color: #616160;

          h5 {
            font-size: 1.35rem;
          }
        }
      }

      .ready {
        color: $csecondary;
        font-size: 2.2rem;
        font-weight: 900;
        letter-spacing: 1px;
      }
    }

    .col-play {
      .box-play {
        position: relative;
        padding-bottom: 100px;
        @include flex-center-xy();
        flex: 0 0 100%;
        max-width: 100%;
        height: 100%;
        border: 4px solid $csecondary;
        background-color: $csecondary;

        .circle {
          @include flex-center-xy();
          width: 220px;
          height: 220px;
          cursor: pointer;
          border-radius: 50%;
          background-color: #ffffff;

          .icon-play {
            margin-left: 7%;
            font-size: 5rem;
            color: $cprimary;
          }

          &:hover {
            @include cp-property(transform, scale(1.07));
          }
        }

        .bottom {
          position: absolute;
          bottom: 28px;
          left: 0;
          width: 100%;
          text-align: center;

          a {
            display: inline-block;
            padding: 8px 30px;
            color: #ffffff;
            font-size: 1.8rem;
            font-weight: 900;
            border: 6px solid $cprimary;
            border-radius: 40px;
            background-color: $csecondary;
            text-decoration: none !important;

            &:hover {
              border-color: $cprimary2;
              background-color: $csecondary2;
            }
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      .col-play {
        margin-top: 15px;

        .box-play {
          padding-top: 30px;
          padding-bottom: 120px;

          .circle {
            width: 180px;
            height: 180px;
          }
        }
      }
    }
  }

  .why-section {
    padding-top: 40px;
    padding-bottom: 40px;

    .long-title {
      position: relative;
      padding: 15px 0;
      width: 100%;
      color: #ffffff;
      text-align: center;
      background-color: $cprimary;

      h2 {
        letter-spacing: 1px;
        font-weight: 900;
      }
    }

    .pink-line {
      position: relative;
      margin: 15px 0;
      width: 100%;
      height: 29px;
      background-color: $csecondary;
    }

    .main-container {
      >.row {
        justify-content: center;
      }

      .circle {
        @include flex-center-xy();
        padding: 5px;
        margin: 10px;
        width: 260px;
        height: 260px;
        border: 10px solid $cprimary;
        border-radius: 50%;

        .inside {
          @include flex-center-xy();
          padding: 20px;
          width: 100%;
          height: 100%;
          text-align: center;
          border: 6px solid $csecondary;
          border-radius: 50%;

          h3 {
            display: inline-block;
            width: 100%;
            font-size: 1.15rem;
            font-weight: 900;
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      .main-container {
        .circle {
          padding: 2px;
          margin: 5px;
          width: 180px;
          height: 180px;
          border-width: 6px;

          .inside {
            padding: 15px;
            border-width: 4px;

            h3 {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }

  .testimonials-section {
    .long-title {
      position: relative;
      padding: 7px 0;
      width: 100%;
      color: #ffffff;
      text-align: center;
      background-color: $csecondary;

      h2 {
        letter-spacing: 1px;
        font-size: 2.8rem;
        font-weight: 900;
      }
    }

    .green-line {
      position: relative;
      margin: 15px 0;
      width: 100%;
      height: 29px;
      background-color: $cprimary;
    }

    .main-container {
      padding-top: 15px;
      padding-bottom: 20px;

      .col-testimonial {
        padding-top: 5px;
        padding-bottom: 5px;

        .box {
          position: relative;
          background-color: $soft-gray;
          height: 450px;
          cursor: pointer;

          .circle {
            position: relative;
            width: 80px;
            height: 80px;
            line-height: 1;
            border-radius: 50%;
            background-color: #ffffff;
            z-index: 5;
            @include cp-property(transform, scale(1.0));

            .icon-play {
              margin-left: 7%;
              color: $cprimary;
              font-size: 2rem;
            }
          }

          &:hover {
            opacity: 0.90;

            .circle {
              @include cp-property(transform, scale(1.15));
            }
          }
        }
      }
    }

    @media screen and (min-width: 576px) {
      .main-container {
        >.row {
          margin-left: -5px;
          margin-right: -5px;
        }

        .col-testimonial {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    @media screen and (max-width: 575px) {
      .main-container {
        padding-top: 0;
        padding-bottom: 0;

        .col-testimonial {
          padding-top: 7px;
          padding-bottom: 8px;

          .box {
            height: 470px;
          }
        }
      }
    }
    @media screen and (max-width: 380px) {
      .long-title {
        h2 {
          font-size: 2.4rem;
        }
      }
    }
  }

  .reg-section {
    padding-top: 10px;
    padding-bottom: 10px;

    .long-title {
      position: relative;
      display: inline-block;
      padding: 11px 0;
      width: 100%;
      color: #ffffff;
      text-align: center;
      text-decoration: none !important;
      background-color: $cprimary;

      h2 {
        letter-spacing: 3px;
        font-size: 2.4rem;
        font-weight: 900;
      }

      &:hover {
        background-color: $cprimary2;
      }
    }
  }

}
