.sweet-style-1 {
  .sweet-box-actions {
    top: 2px !important;

    .sweet-action-close {
      @include transition(250ms);

      &:hover {
        background-color: #ea7c7c !important;
      }
    }
  }

  .sweet-title {
    display: flex;
    align-items: center;
    padding: 6px 50px !important;
    width: 100%;
    max-width: 100%;
    height: inherit !important;
    min-height: 45px;

    h2 {
      width: 100%;
      font-size: 20px !important;
      font-weight: 600 !important;
      text-align: center;
    }
  }
}
