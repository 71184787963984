.cus-f-group-1{
  position: relative;
  margin-bottom: 2rem;

  &.no-label{ // Agregar clase css cuando no se tendrá un label en el componente
    >div{
      padding-top: 0 !important;
    }
  }

  >label, >legend{
    position: absolute;
    display: inline-block;
    padding: 0 6px;
    width: auto;
    max-width: 85%;
    top: -6px;
    left: 28px;
    color: #9b9b9b;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1;
    background: #fff;
    z-index: 5;
    pointer-events: none !important;
  }

  >div{
    position: relative;
    padding-top: 4px;
    padding-bottom: 2px;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 0.40rem;

    .custom-select, .form-control{
      color: #353535 !important;
      border: 0 !important;
      border-radius: 0;
      background-color: transparent !important;
    }

    .custom-radio{
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 42px;
      line-height: 1.1;

      &:first-child{
        margin-top: 9px;
      }
      &:last-child{
        margin-bottom: 5px;
      }

      .custom-control-label{
        width: auto;
        color: #353535 !important;
        font-weight: 400;
        line-height: 1;

        &:before, &:after{
          top: -0.05rem;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #000000;
        border-color: #000000;
      }
    }

    .fileinput{
      padding: 9px 6px 7px 6px;

      .fileinput-con{
        max-width: 100%;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 575px){
    padding-top: 4px;

    >div{
      padding-top: 7px;

      .fileinput{
        padding-top: 5px;
      }
    }
  }
}

.cus-f-group-2{
  .form-control, .custom-select, textarea{
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    border: 0;
    border-bottom: 2px solid $csecondary !important;
    border-radius: 0;
  }

  textarea{
    overflow: auto;
    overflow-x: hidden;
    resize: none;
  }
}

.cus-f-checkbox{
  padding-left: 33px;

  .custom-control-label{
    color: #6a6969;
    font-size: 0.95rem;

    a{
      color: #6a6969;
      text-decoration: underline;
    }
  }

  .custom-control-input:checked ~ .custom-control-label{
    &:before{
      background-color: #000000;
      border-color: #000000;
    }
  }
}
