#join-us-page{
	background-color: #f9fafd;

	.page-title-section{
		background-color: $csecondary;

		.x-title{
			color: #ffffff;
			font-size: 1.4rem;
			font-weight: 300;
			text-align: center;
			text-transform: uppercase;
		}
	}

	.banner-section {
		position: relative;
		background-color: $soft-gray;
		margin-bottom: 30px;

		img {
			width: 100%;
		}

		.img-desktop {}
		.img-mobile {
			display: none;
		}

		@media screen and (max-width: 991px) {
			.img-desktop {
				display: none;
			}
			.img-mobile {
				display: block !important;
			}
		}
	}

	.text-section{
		color: #212529;
		line-height: 1.2;

		.box-elements {
			margin-right: -8px;
			margin-left: -8px;

			.col-el {
				padding-left: 8px;
				padding-right: 8px;
				height: 100%;
				margin-top: 8px;
				margin-bottom: 8px;

				.box {
					position: relative;
					@include flex-center-xy();
					padding: 14px 20px;
					width: 100%;
					min-height: 68px;
					color: #ffffff;
					border-radius: 0.35rem;
					background-color: $csecondary;

					h5 {
						font-size: 1.0rem;
						line-height: 1.05;
					}
				}
			}
		}

		@media screen and (max-width: 1199px) {
			.box-elements {
				.col-el {
					.box {
						min-height: 80px;
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			.box-elements {
				.col-el {
					.box {
						min-height: 55px;
					}
				}
			}
		}
	}

	.b-title{
		margin-bottom: 24px;
		color: $ctertiary;
		font-size: 1.5rem;
	}

	.circle-num{
		position: relative;
		display: inline-block;
		margin-right: 4px;
		top: 9px;
		color: white;
		width: 36px;
		height: 36px;

		i{
			@extend .flex-center-xy;
			position: absolute;
			padding-top: 2px;
			padding-left: 1px;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			font-size: 1.3rem;
			font-style: normal;
			border-radius: 50%;
			background-color: $ctertiary;
		}
	}

	.box-step{
		position: relative;
		margin-bottom: 25px;
		border-radius: 6px;
		box-shadow: 0px 0px 4px rgba(0,0,0,0.20);
		background-color: #ffffff;
	}

	.box-note {
		position: relative;
		display: block;
		padding: 14px;
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: 20px;
		color: #ffffff;
		font-weight: 600;
		text-align: center;
		border-radius: 6px;
		background-color: $ctertiary;

		@media screen and (max-width: 575px) {
			font-size: 0.90rem;
  		line-height: 1.35;
			border-radius: 0;
		}
	}

	@media screen and (min-width: 992px){
		.box-step{
			padding: 16px 35px 24px 35px;
		}
	}

	@media screen and (max-width: 991px){
		.box-step{
			padding-top: 12px;
		}
	}

	@media screen and (max-width: 575px){
		.b-title{
			font-size: 1.3rem;
		}
	}
}
